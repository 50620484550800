import { Control, FieldValues, Path, useController } from 'react-hook-form'

import { Rating, RatingProps } from './rating'

type RatingPropsWithOmit = Omit<RatingProps, 'onChange' | 'value'>

type FormRatingProps<T extends FieldValues> = RatingPropsWithOmit & {
  name: Path<T>
  control: Control<T>
}

export const FormRating = <T extends FieldValues>({
  name,
  control,
  ...props
}: FormRatingProps<T>) => {
  const {
    field: { value, onChange },
  } = useController({ control, name })

  return <Rating value={value} onChange={onChange} {...props} />
}
