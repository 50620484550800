'use client'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

export interface BadgeExpirationProps {
  expirationDate: string
}

export const BadgeExpiration = ({ expirationDate }: BadgeExpirationProps) => {
  const t = useTranslations('Product')

  return (
    <div className="bg-primary py-[0.15rem]">
      <span
        className={twJoin('text-white', 'uppercase font-bold', 'px-2 py-0.5')}
        suppressHydrationWarning
      >
        {`${t('detail.badge.expiration')} ${expirationDate}`}
      </span>
    </div>
  )
}
