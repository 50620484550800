import { ProductName } from '@/components/product-name'

export function PurchaseDialogConfigurableVariantsSkeleton(): JSX.Element {
  return (
    <div>
      <div className="bg-gray-100 w-full h-40"></div>
      <ProductName></ProductName>
      <div className="bg-gray-200 w-full h-7 mt-3"></div>
      <div className="bg-gray-200 w-full h-7 mt-3"></div>
      <div className="bg-gray-200 w-1/3 h-7 mt-3"></div>
      <div className=" bg-gray-500 relative h-14 mt-3"></div>
    </div>
  )
}
