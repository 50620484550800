import { ProductLabelV2Fragment } from '@/api'
import TextLabel from './text-label'
import ImageLabel from './image-label'

type ImageLabelsV2Props = {
  labels: ProductLabelV2Fragment[]
  variantLabels?: ProductLabelV2Fragment[]
}

// @param variantLabels primarly is used in PDP no need to sent when used anywhere else
export const ImageLabelsV2 = ({
  labels,
  variantLabels,
}: ImageLabelsV2Props) => {
  const imageLabels = labels.filter((label) => label.type === 'img')
  const textLabels = labels.filter((label) => label.type === 'text')

  // on PDP (Gallery component), type "sale" display only for selected variant
  const saleLabels = variantLabels
    ? variantLabels?.filter((label) => label.type === 'sale')
    : labels.filter((label) => label.type === 'sale')

  return (
    <div className="absolute h-full w-full top-0 left-0 pointer-events-none overflow-hidden">
      <div className="relative h-full">
        <div className="absolute left-0 top-0 w-[50%] h-[80%]">
          {textLabels.map(({ label, bg, content }) => (
            <TextLabel key={label} bg={bg} content={content} />
          ))}
        </div>
        <div className="absolute right-0 top-0 w-[50%] h-[80%]">
          {saleLabels.map(({ label, bg, content }) => (
            <TextLabel key={label} bg={bg} content={content} position="right" />
          ))}
        </div>
        <div className="absolute right-0 bottom-0 flex justify-end max-w-[50%] h-[20%] gap-1">
          {imageLabels.map(({ label, src, content }) => (
            <ImageLabel key={label} src={src} content={content} />
          ))}
        </div>
      </div>
    </div>
  )
}
