'use client'

import { useTranslations } from 'next-intl'
import { HTMLAttributes } from 'react'

import { IconButton } from './icon-button'
import { CartIcon } from './icons/svg'
import { selectors } from '@/common/constants/selectors-constants'
import { cn } from '../utils/lib'

export type AddToCartButtonProps = {
  disabled: boolean
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
} & HTMLAttributes<HTMLButtonElement>

export function AddToCartButton({
  disabled,
  onClick,
  className,
  ...props
}: AddToCartButtonProps): JSX.Element {
  const t = useTranslations('Product')

  return (
    <>
      <IconButton
        title={t('common.button.addToCart')}
        data-test={selectors.PDP.addToCartRecom}
        className={cn('absolute m-0 left-0 bottom-0 w-11 h-11', className)}
        variant="shadow"
        disabled={disabled}
        aria-disabled={disabled}
        onClick={onClick}
        aria-label={t('common.button.addToCart')}
        {...props}
      >
        <CartIcon width="32px" height="32px" fill="#ffffff" />
      </IconButton>
    </>
  )
}
