import { PropsWithChildren } from 'react'
import { twJoin } from 'tailwind-merge'

import { selectors } from '@/common/constants/selectors-constants'

export type ProductNameProps = PropsWithChildren<{
  size?: 'sm' | 'md' | 'lg'
  dialogVariant?: boolean
}>

export function ProductName({
  size = 'sm',
  dialogVariant,
  children,
}: ProductNameProps): JSX.Element {
  return (
    <span
      data-test={selectors.PDP.recomProductsTitle}
      className={twJoin(
        `product-name block my-2 text-${size} font-bold text-black overflow-hidden`,
        dialogVariant
          ? [size === 'lg' ? 'leading-6 mb-4' : '']
          : `h-[2.5rem]${children ? '' : ' bg-gray-200 w-2/3'}`,
      )}
    >
      {children}
    </span>
  )
}
