'use client'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

import { checkEstimatedDateOfArrival } from '@/utils'
import { useProductDataContext } from '@/providers/product-data/product-data-context'

export interface BadgeStockStatusProps {
  inStock: boolean
  longTermUnavailable: boolean
}

export const BadgeStockStatus = ({
  inStock,
  longTermUnavailable,
}: BadgeStockStatusProps) => {
  const t = useTranslations('Product')

  const { configurableProductVariant } = useProductDataContext()
  const estimatedDateOfArrival = checkEstimatedDateOfArrival(
    configurableProductVariant?.product?.estimated_date_of_arrival,
  )

  const stockStatus =
    'common.status.' +
    (longTermUnavailable
      ? 'longTermUnavailable'
      : inStock
      ? 'inStock'
      : 'outOfStock')

  return (
    <div className="bg-primary py-[0.15rem]">
      <span
        className={twJoin('text-white', 'uppercase font-bold', 'px-2 py-0.5')}
      >
        {t(stockStatus)}
      </span>
      {estimatedDateOfArrival && !inStock && (
        <span
          className={twJoin('text-white', 'uppercase font-bold', 'px-2 py-0.5')}
          suppressHydrationWarning
        >
          {t('common.expectingArrivalDate')}
          :&nbsp;
          {estimatedDateOfArrival}
        </span>
      )}
    </div>
  )
}
