'use client'

import { useQuery } from '@tanstack/react-query'

import { useStoreContext } from '@/providers'
import { ProductDetailStorageBadges } from './badges'
import { Price } from './price/price'
import { STORE_CODES } from '@/common/utils'
import { Domain } from '@/common/types'
import { HttpMethod } from '@/common/api/rest/fetch'
import { DeliveryData } from 'src/app/api/delivery-date-info/route'
import { MiddlewareHeader } from '@/common/types/header-types'

interface ProductDetailStorageProps {
  isConfigurableProduct: boolean
  isGiftCardProduct: boolean
  inStock: boolean
  longTermUnavailable: boolean
  small?: boolean
}

export const ProductDetailStorage = ({
  isConfigurableProduct,
  isGiftCardProduct,
  inStock,
  longTermUnavailable,
  small,
}: ProductDetailStorageProps) => {
  const { storeCode } = useStoreContext()

  const { data: deliveryData } = useQuery({
    queryKey: ['deliveryData'],
    queryFn: () =>
      fetch('/api/v1/delivery-date-info', {
        method: HttpMethod.GET,
        headers: {
          [MiddlewareHeader.StoreCode]: storeCode,
        },
      }).then((response) => response.json() as unknown as DeliveryData),
    enabled: storeCode !== STORE_CODES[Domain.COM],
  })

  if (small) {
    return (
      <div className="flex flex-col text-left">
        {isConfigurableProduct && !longTermUnavailable && (
          <Price deliveryData={deliveryData} inStock={inStock} small />
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex order-2 md:order-1">
        <ProductDetailStorageBadges
          deliveryData={deliveryData}
          inStock={inStock}
          longTermUnavailable={longTermUnavailable}
          isGiftCardProduct={isGiftCardProduct}
        />
      </div>
      {isConfigurableProduct && !longTermUnavailable && (
        <div className="flex-col order-1 md:order-1 mb-5">
          <Price deliveryData={deliveryData} inStock={inStock} />
        </div>
      )}
    </div>
  )
}
