import { UnitPrice as UnitPriceType } from '@/api'
import { formatPrice } from '@/common/utils/price-utils'
import { useStoreContext } from '@/providers'

interface UnitPriceProps {
  unitPrice: UnitPriceType
}

// TODO: this will be later refactored to use the new API (at the moment it is compatible with both old one and new one)

export const UnitPrice = ({ unitPrice }: UnitPriceProps) => {
  const { storeCode, storeConfig } = useStoreContext()
  const unit = unitPrice?.unit?.split('/')[1]
  const formattedUnitPrice = formatPrice({
    currency: unitPrice.currency,
    storeCode,
    price: unitPrice.value,
  })
  const dualCurrency = {
    rate: storeConfig.checkoutCurrency?.rate,
    format: storeConfig.checkoutCurrency?.format,
  }

  const formattedDualPrice =
    dualCurrency?.rate && dualCurrency?.format
      ? formatPrice({
          storeCode,
          price: unitPrice.value * dualCurrency.rate,
          currencyFormat: dualCurrency.format,
          dualCurrency: true,
        })
      : undefined

  return (
    <span className="text-xs">
      {formattedUnitPrice}
      {dualCurrency?.format && formattedDualPrice && (
        <span className="text-grey-300 ml-1 font-normal">
          ~({formattedDualPrice})
        </span>
      )}
      /{unit || unitPrice.unit}
    </span>
  )
}
