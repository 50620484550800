import type { ReactElement } from 'react'
import { ProductLabelV2Fragment } from '@/api'

type TextLabelProps = ProductLabelV2Fragment & {
  position?: 'left' | 'right'
}

export default function TextLabel({
  bg,
  content,
  label,
  position = 'left',
}: TextLabelProps): ReactElement {
  return (
    <div
      key={label}
      className="data-[position=right]:ml-auto flex flex-col justify-start max-w-[180px]"
      data-position={position}
    >
      <div
        className="text-white bg-[#ff4100] text-center uppercase md:p-1 rounded font-bold md:text-sm text-xs  md:m-1 p-[1px] m-[1px]"
        style={{ background: bg ?? '#ff4100' }}
      >
        {content}
      </div>
    </div>
  )
}
