import { AddToCartButton } from '../add-to-cart-button'
import { ProductName } from '../product-name'
import { Rating } from '../rating'
import { Currency } from '../currency'

export function ProductListItemSkeleton(): JSX.Element {
  return (
    <div>
      <div className="relative max-h-[215px] lg:h-[215px] w-fit md:w-auto">
        <div className="h-[215px] bg-gray-100">
          <AddToCartButton disabled />
        </div>
      </div>
      <div className="bg-gray-200 w-2/3"></div>
      <ProductName></ProductName>
      <Rating
        value={0}
        voteCount={0}
        className="ml-[-5px] mt-3"
        voteCountClassName="font-bold text-xs m-0"
      />
      <Currency className="text-sm font-bold text-secondary mt-1 h-[1em] block bg-gray-200 w-1/3" />
    </div>
  )
}
